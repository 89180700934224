<template>
  <div v-if="child">
    <div v-if="kibig">
      <div v-if="raw">
        <p>KiBiG-Id : {{child.kibigId}}</p>
        <p>KiBiG-Name : {{child.kibigName}}</p>
      </div>
      <div v-else>
        <table class="hover">
          <thead>
          </thead>
          <tbody>
          <tr>
            <td class="td-width"><p>Id</p></td>
            <td><p><span>{{child.kibigId}}</span></p></td>
          </tr>
          <tr>
            <td><p>Name</p></td>
            <td><p><span>{{child.kibigName}}</span></p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="compensation">
      <div v-for="(date, index) of compensationDates" :key="child.id + date + index">
        <md-checkbox class="md-primary" v-model="compensationValues[index]" disabled>
          Antrag auf Corona Beitragsersatz für <span style="color: cornflowerblue">{{date}}</span>
        </md-checkbox>
        <br>
      </div>
    </div>
    <div v-if="emergency">
      <table v-if="child.emergencyPersonIds.length > 0" class="hover">
        <tbody>
          <tr v-if="!emergencyLight">
            <td><p class="md-subheading" style="color: lightseagreen">Ansprechpartner</p></td>
            <td><p class="md-subheading" style="color: lightseagreen">Notfallnummern</p></td>
          </tr>
          <tr v-for="emergencyPersonId of child.emergencyPersonIds" :key="emergencyPersonId">
            <td class="td-width"><p v-html="getPersonName(emergencyPersonId)"></p></td>
            <td><p v-html="getPersonPhoneNumbers(emergencyPersonId)"></p></td>
          </tr>
        </tbody>
      </table>
      <p v-else-if="!(child.emergencyPhonenumber && child.emergencyPhonenumber.length > 0)"><span class="label alert expanded">ACHTUNG: Keine Bezugspersonen ausgewählt!</span></p>
      <table v-if="!emergencyLight" class="hover">
        <tbody v-if="child.emergencyPhonenumber">
          <tr>
            <td class="td-width"><p class="label">Alternative Nummer</p></td>
            <td>
              <span v-if="child.emergencyPhonenumber" class="label alert">{{child.emergencyPhonenumber}}</span>
              <span v-else class="label medium alert">Keine Angabe!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="insurance">
      <div v-if="child.healthInformation">
        <table class="hover">
          <tbody>
            <tr>
              <td><p>Versicherungsnehmer</p></td>
              <td><p><span v-html="getPersonName(child.healthInformation.insuranceHolderPersonId)"></span></p></td>
            </tr>
            <tr>
              <td class="td-width">privat versichert</td>
              <td><p><span>{{booleanText(child.healthInformation.privateInsurance)}}</span></p></td>
            </tr>
            <tr>
              <td><p>Krankenversicherung</p></td>
              <td><p><span>{{child.healthInformation.insuranceName}}</span></p></td>
            </tr>
            <tr>
              <td><p>Versicherungsnummer</p></td>
              <td><p><span>{{child.healthInformation.insuranceNumber}}</span></p></td>
            </tr>
          </tbody>
        </table>
        <ContactView :contact="child.healthInformation.insuranceContact" :insurance="insurance"/>
      </div>
    </div>
    <div v-if="characteristics">
      <md-tabs md-elevation="1" style="height: 310px;">
        <template slot="md-tab" slot-scope="{ tab }">
          {{ tab.label }} <b class="badge" v-if="tab.data.badge">1</b>
          <md-tooltip>
            <div v-if="tab.data.badge && tab.data.reason === 'gdpr'">Einwilligung zur Datenverarbeitung für <b>{{tab.data.child.masterdata.fullName}}</b> fehlt!</div>
            <div v-if="tab.data.badge && tab.data.reason === 'vaccination'">Gesetzlich vorgeschriebene Impfung für <b>{{tab.data.child.masterdata.fullName}}</b> fehlt!</div>
          </md-tooltip>
        </template>
        <md-tab md-label="Allgemein">
          <md-checkbox class="md-primary" v-model="child.handicapped" disabled>Behinderung
            <span v-if="child.handicapped">
              <span v-for="handicapType of child.handicapTypes" :value="handicapType" :key="handicapType">&nbsp;&nbsp;&nbsp;{{labels.handicapType[handicapType]}}</span>
              <span>&nbsp;&nbsp;&nbsp;<i>({{labels.bezirkFachkraftstundenType[child.bezirkFachkraftstunden]}})</i></span>
            </span>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.migrantBackground" disabled>Migrationshintergrund
            <span v-if="child.migrantBackground" style="color: green;">
                <span>&nbsp;&nbsp;&nbsp;<md-icon style="color: green;">location_on</md-icon>&nbsp;{{labels.country[child.countryOfOrigin]}}<md-tooltip>Herkunftsland</md-tooltip></span>
                <span>&nbsp;&nbsp;&nbsp;<md-icon style="color: green;">record_voice_over</md-icon>&nbsp;{{labels.language[child.nativeLanguage]}}<md-tooltip>Muttersprache</md-tooltip></span>
                <span>&nbsp;&nbsp;&nbsp;<md-icon style="color: green;">record_voice_over</md-icon>&nbsp;{{labels.language[child.familyLanguage]}}<md-tooltip>Familiensprache</md-tooltip></span>
              </span>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.nutritionInfo.porkMeatAllowed" disabled>Darf Schweinefleisch essen</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.possiblePreschooler" disabled>Mögliches Vorschulkind</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.preschooler" disabled>Vorschulkind</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.shareInformationAllowed" disabled>Datenweitergabe-Erlaubnis</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.sibling" disabled>Geschwister
            <span v-if="child.sibling && child.siblings && child.siblingNames">
                <span v-for="(id, index) of child.siblings" :key="id">&nbsp;
                  <a @click="selectChild(id)">{{child.siblingNames[index]}}<md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{child.siblingNames[index]}}</b></md-tooltip></a>
                  &nbsp;
                </span>
              </span>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.gettingWirtschaftlicheJugendhilfe" disabled>Wirtschaftliche Jugendhilfe</md-checkbox>
          <br>
        </md-tab>
        <md-tab md-label="Gesundheit"
                :md-template-data="{ badge: !child.firstMeaslesVaccinationDate, child: child, reason: 'vaccination'}">
          <md-checkbox class="md-primary" v-model="child.nutritionInfo.allergic" disabled>Allergien<span
              v-if="child.nutritionInfo.allergic" style="color: orangered;"> ({{child.nutritionInfo.allergies}})</span>
          </md-checkbox>
          <div v-if="child.exemptionMeaslesVaccination">
            <md-checkbox class="md-primary" v-model="child.exemptionMeaslesVaccination" disabled>Freistellung Masernimpfung</md-checkbox>
            <br>
          </div>
          <table v-else class="hover" style="padding-bottom: 8px;">
            <tbody>
            <tr>
              <td style="width: 200px;"><p>Erste Masernimpfung</p></td>
              <td><p>
                <span v-if="child.firstMeaslesVaccinationDate">{{child.firstMeaslesVaccinationDate | moment("DD.MM.YYYY")}}</span>
                <span v-else><b style="color: red">nicht geimpft!</b></span>
              </p></td>
            </tr>
            <tr v-if="!child.exemptionSecondMeaslesVaccination">
              <td style="width: 200px;"><p>Zweite Masernimpfung</p></td>
              <td><p>
                <span v-if="child.secondMeaslesVaccinationDate">{{child.secondMeaslesVaccinationDate | moment("DD.MM.YYYY")}}</span>
                <span v-else><b style="color: red">nicht geimpft!</b></span>
              </p></td>
            </tr>
            </tbody>
          </table>
          <div v-if="child.exemptionSecondMeaslesVaccination">
            <md-checkbox class="md-primary" v-model="child.exemptionSecondMeaslesVaccination" disabled>Freistellung 2. Masernimpfung</md-checkbox>
            <br>
          </div>
        </md-tab>
        <md-tab md-label="Zustimmungen"
                :md-template-data="{ badge: !child.masterdata.dsgvoConfirmationObtained, child: child, reason: 'gdpr'}">
          <md-checkbox class="md-primary" v-model="child.masterdata.dsgvoConfirmationObtained" disabled>
            <div :class="checkBoxWarningClass(!child.masterdata.dsgvoConfirmationObtained)" >
              Einwilligung zur Datenverarbeitung gegeben
            </div>
            <md-tooltip>
              <div v-if="!child.parentalConsents.dsgvoConfirmationObtained">
                Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
                zur Verarbeitung der personenbezogenen Daten des Kindes muss<br>
                seitens eines Erziehungsberechtigten schriftlich gegeben sein.
              </div>
            </md-tooltip>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.applyPlasterSalveAllowed" disabled>Pflaster applizieren /
            Salbengabe
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.beingPhotographedPrivatelyAllowed" disabled>Fotonutzung für
            interne Zwecke erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.beingPhotographedPubliclyAllowed" disabled>Fotonutzung für
            öffentliche Zwecke erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.contactToAnimalsAllowed" disabled>Kontakt mit Tieren erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.goSwimmingAllowed" disabled>Schwimmbadbesuch inkl. Schwimmen
            erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.removeTickAllowed" disabled>Zeckenentfernung erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.ridingCarAllowed" disabled>Beförderung in Kraftfahrzeugen
            erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.applySuncreamAllowed" disabled>Sonnencreme anwenden erlaubt
          </md-checkbox>
          <br>
        </md-tab>
        <md-tab md-label="Individuell">
          <md-content class="md-scrollbar">
            <div v-if="child.freeAttributes.length <= 0">
              <p><i>Keine individuellen Merkmale angegeben.</i></p><br>
              <h6>Im Bereich <router-link to="/Verwaltung/Anpassungen">Verwaltung / Anpassungen</router-link> können weitere individuelle Merkmale für Kinder definiert werden.</h6>
            </div>
            <div v-else>
              <div v-for="(attribute, index) of child.freeAttributes" :key="index" class="grid-x grid-padding-x">
                <div class="cell">
                  <div v-if="attribute.type === 'BOOLEAN'">
                    <md-checkbox class="md-primary" v-model="attribute.booleanValue" disabled>{{attribute.name}}</md-checkbox>
                    <br>
                  </div>
                </div>
              </div>
              <table class="hover" style="padding-bottom: 8px;">
                <tbody>
                <tr v-for="(attribute, index) of child.freeAttributes" v-if="attribute.type !== 'BOOLEAN'" :key="index">
                  <td style="width: 200px;"><p>{{attribute.name}}</p></td>
                  <td>
                    <span v-if="attribute.type === 'STRING'">{{attribute.stringValue}}</span>
                    <span v-if="attribute.type === 'INTEGER'">{{attribute.intValue}}</span>
                    <span v-if="attribute.type === 'DOUBLE'">{{attribute.doubleValue}}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </md-content>
        </md-tab>
      </md-tabs>
    </div>
    <div v-if="!(kibig || compensation || emergency || insurance || characteristics)">
      <div v-if="raw">
        <p>Alter : {{child.age}}</p>
        <p>Bereich : {{child.divisionTypeLabel}}</p>
        <p>Förderkommune : {{child.promotionCommunityName}}</p>
        <p>Behinderung : {{booleanText(child.handicapped)}}</p>
        <p>Migrationshintergrund : {{booleanText(child.migrantBackground)}}</p>
        <p>Mögliches Vorschulkind : {{booleanText(child.possiblePreschooler)}}</p>
        <p>Vorschulkind : {{booleanText(child.preschooler)}}</p>
        <p>Datenweitergabe-Erlaubnis : {{booleanText(child.shareInformationAllowed)}}</p>
        <p>Geschwister : {{booleanText(child.sibling)}} <span v-if="child.sibling">{{child.siblingNames}}</span></p>
        <p>Wirtschaftliche Jugendhilfe : {{booleanText(child.gettingWirtschaftlicheJugendhilfe)}}</p>
        <p>Eintritts-Datum : {{child.entryDate | moment("DD.MM.YYYY")}}</p>
        <p>Austritts-Datum : {{child.exitDate | moment("DD.MM.YYYY")}}</p>
      </div>
      <div v-else>
        <table class="hover">
          <thead>
          </thead>
          <tbody>
          <tr>
            <td class="td-width"><p>Alter</p></td>
            <td><p><span>{{child.ageTextual}}</span></p></td>
          </tr>
          <tr>
            <td><p>Bereich</p></td>
            <td><p><span>{{labels.divisiontype[child.divisiontype]}}</span></p></td>
          </tr>
          <tr v-if="child.divisiontype === 'HORT'">
            <td><p>Klassenstufe</p></td>
            <td><p><span>{{labels.klassenstufe[child.klassenstufe]}}</span></p></td>
          </tr>
          <tr>
            <td><p>Förderkommune</p></td>
            <td><p><span>{{child.promotionCommunityName}}</span></p></td>
          </tr>
          <tr>
            <td><p>Eintritt</p></td>
            <td><p><span>{{child.entryDate | moment("DD.MM.YYYY")}}</span></p></td>
          </tr>
          <tr>
            <td><p>Austritt</p></td>
            <td><p><span>{{child.exitDate | moment("DD.MM.YYYY")}}</span></p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import jQuery from "jquery";
  import ContactView from "@/components/views/ContactView";

  export default {
    name: 'ChildView',

    components: {
      ContactView,
    },

    props: {
      child: {
        required: true
      },
      raw: {
        required: false
      },
      kibig: {
        required: false
      },
      compensation: {
        required: false
      },
      emergency: {
        required: false
      },
      emergencyLight: {
        required: false
      },
      insurance: {
        required: false
      },
      characteristics: {
        required: false
      },
    },

    mounted() {
      this.buildCompensationData();
    },

    watch: {
      child() {
        this.buildCompensationData();
      },
    },

    data() {
      return {
        compensationDates: [],
        compensationValues: [],
      }
    },

    methods: {

      selectChild(id) {
        jQuery('#' + id).trigger('click');
      },

      buildCompensationData() {
        this.compensationDates = [];
        Object.keys(this.child.beitragsersatz).forEach(key => {
          this.compensationDates.push(Vue.moment(key, 'YYYYMM').format('MMMM YYYY'));
        });
        this.compensationValues = [];
        Object.values(this.child.beitragsersatz).forEach(value => this.compensationValues.push(value));
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      checkBoxWarningClass(warn) {
        return warn ? 'cb-warn' : '';
      },

      getPersonName(personId) {
        let personName = '';
        let htmlPrefix = '<span class="label">';
        let htmlSuffix = '</span> ';
        this.child.relatedPersons.forEach(person => {
          if (person.id === personId) {
            personName = htmlPrefix + person.fullName + htmlSuffix;
          }
        });
        return personName;
      },

      getPersonPhoneNumbers(personId) {
        let phoneNumbers = '';
        let htmlPrefix = '<span class="label medium alert" style="margin: 1px;">';
        let htmlSuffix = '</span> ';
        this.child.relatedPersons.forEach(person => {
          if (person.id === personId) {
            phoneNumbers = (person.contact.mobilePhone ? htmlPrefix + person.contact.mobilePhone + htmlSuffix : '') +
                (person.contact.businessPhone ? htmlPrefix + person.contact.businessPhone + htmlSuffix : '') +
                (person.contact.phone ? htmlPrefix + person.contact.phone + htmlSuffix : '');
          }
        });

        if (phoneNumbers.trim().length <= 0) {
          phoneNumbers = htmlPrefix + 'Keine Rufnummern hinterlegt!' + htmlSuffix;
        }
        return phoneNumbers;
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .md-checkbox {
    margin: 0;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .cb-warn {
    color: red;
    font-weight: bold;
  }

  .td-width {
    width: 210px;
  }

  .label {
    font-size: large;
  }

  .md-content {
    max-height: 260px;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
